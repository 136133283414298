<template>
    <div>
        <div>
            <input type="date"
                   class="date-field"
                   v-model="inputDate"
                   :disabled="readOnly || !isActive || isAutoGenerated">
        </div>

        <!--next button-->
        <br>
        <mdbBtn v-show="!readOnly && isActive"
                color="primary"
                class="next-btn"
                name="number"
                id="number"
                :disabled="!hasAnyInput"
                @click="handleAnswer()">
            {{isLastQuestion ? 'Complete' : 'Next'}}
            <mdb-icon v-show="waiting" icon="spinner" :spin="true"/>
        </mdbBtn>
    </div>
</template>

<script>
    import {mdbBtn, mdbIcon} from "mdbvue";

    export default {
        name: "questionTypeDob",
        props: ['question', 'enrollmentSurveyPatients', 'isActive', 'isSubQuestion', 'onDoneFunc', 'isLastQuestion', 'waiting', 'readOnly'],
        components: {
            mdbBtn,
            mdbIcon
        },
        data() {
            return {
                isAutoGenerated: false,
                inputDate: this.enrollmentSurveyPatients.dob[0],
                questionOptions: [],
                showNextButton: false,
                keys: [],
            }
        },
        computed: {
            hasAnyInput() {
                return this.inputDate.length > 0 ? this.showNextButton = true : this.showNextButton = false;
            },


        },

        methods: {
            handleAnswer() {
                if (!this.hasAnyInput) {
                    return;
                }

                const answer = {
                    value: this.inputDate
                };


                this.onDoneFunc(this.question.id, this.questionTypeAnswerId, answer, this.isLastQuestion);
            },
        },
    }
</script>

<style scoped>
    .btn-primary {
        background-color: #50b2e2;
        border-color: #4aa5d2;
    }

    .btn-primary.disabled {
        opacity: 50%;
        background-color: #50b2e2;
        border-color: #4aa5d2;
    }

    .date-field {
        border: none;
        border-bottom: solid 1px rgba(0, 0, 0, 0.1);
        background-color: transparent;
        outline: 0;
        width: 300px;
        height: 30px;
    }


    @media (max-width: 490px) {
        .date-field {
            padding-left: 9px;
            width: 325px;
        }
    }
</style>
