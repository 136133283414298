import { render, staticRenderFns } from "./VitalsSurvey.vue?vue&type=template&id=226d791d&scoped=true&"
import script from "./VitalsSurvey.vue?vue&type=script&lang=js&"
export * from "./VitalsSurvey.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "226d791d",
  null
  
)

export default component.exports