export default [
    {id: "non-clinical", text: "Non-clinical"},
    {id: "MD", text: "MD"},
    {id: "DO", text: "DO"},
    {id: "NP", text: "NP"},
    {id: "PA", text: "PA"},
    {id: "RN", text: "RN"},
    {id: "LPN", text: "LPN"},
    {id: "PN", text: "PN"},
    {id: "CNA", text: "CNA"},
    {id: "MA", text: "MA"},
];