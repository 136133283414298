<template>
    <div>
        <input type="range" name="rangeTypeAnswer">
    </div>
</template>

<script>
    export default {
        mounted() {
            console.log('Component mounted.')
        },
    }
</script>

<style scoped>

</style>