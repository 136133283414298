<template>
    <div>
        <div class="radio">
            <template v-if="styleHorizontal">
                <mdb-btn v-for="answer in possibleAnswers"
                         type="checkbox"
                         class="custom-checkbox"
                         :color="answer.value === selectedAnswer ? 'primary' : 'white'"
                         size="sm"
                         :key="answer.id"
                         :disabled="readOnly || !isActive || isAutoGenerated()"
                         @click="handleAnswer(answer.value)">
                    {{answer.value}}
                    <mdb-icon v-show="waiting && answer.value === selectedAnswer"
                              icon="spinner"
                              :spin="true"/>
                </mdb-btn>
            </template>

            <template v-else>
                <mdb-btn v-for="answer in possibleAnswers"
                         type="button"
                         color="primary"
                         :key="answer.id"
                         :title="answer.value"
                         class="radio"
                         :class="{small: possibleAnswers.length < 3}"
                         :active="answer.value === selectedAnswer"
                         :disabled="readOnly || !isActive || isAutoGenerated()"
                         @click="handleAnswer(answer.value)">
                    {{answer.value}}
                    <mdb-icon v-show="waiting && answer.value === selectedAnswer"
                              icon="spinner"
                              :spin="true"/>
                </mdb-btn>
            </template>

            <input v-if="showDifferentInput"
                   v-for="input in differentInputTypesData"
                   class="text-field"
                   :class="{active: otherSelected, small: possibleAnswers.length < 3}"
                   v-model="otherInputText"
                   :placeholder="input.placeholder"
                   :disabled="readOnly || !isActive"
                   @click="onClickOther()"
                   :type="input.answer_type">

            <div class="clearfix"></div>
        </div>

        <div v-if="isAutoGenerated() || otherSelected"
             :class="isLastQuestion ? 'text-center' : 'text-left'">
            <br>
            <mdbBtn v-show="!readOnly && isActive"
                    color="primary"
                    class="next-btn"
                    name="number"
                    id="number"
                    :disabled="nextButtonDisabled || otherInputInvalid"
                    @click="handleAnswer()">
                {{isLastQuestion ? 'Complete' : 'Next'}}
                <mdb-icon v-show="waiting" icon="spinner" :spin="true"/>
            </mdbBtn>
        </div>
    </div>
</template>

<script>

    import {mdbBtn, mdbIcon} from 'mdbvue';

    export default {
        name: "questionTypeRadio",
        props: ['question', 'isActive', 'isSubQuestion',
            'onDoneFunc', 'styleHorizontal',
            'getAllQuestionsFunc', 'isLastQuestion', 'waiting', 'readOnly'],
        components: {mdbBtn, mdbIcon},

        data() {
            return {
                selectedAnswer: null,
                nextButtonDisabled: false,
                possibleAnswers: [],
                questionOrder: this.question.pivot.order,
                questionOptions: [],
                differentInputTypesData: [],
                showDifferentInput: false,
                otherInputText: "",
                isYesOrNoQuestion: false,
                // isSubQuestion: false,
                showNextButton: false,
                otherSelected: false,
            }
        },

        computed: {

            otherInputInvalid() {
                return !this.isAutoGenerated() && this.otherInputText.length === 0;
            },

            questionHasConditions() {
                //this.isSubQuestion = true;
                return this.question.conditions != null;
            },

            hasOptions() {
                return this.questionOptions.length !== 0;
            },

            hasDifferentInputType() {
                if (this.hasOptions) {
                    const options = this.questionOptions.map(a => a.options);
                    return options[0].hasOwnProperty('answer_type') ? this.showDifferentInput = true : '';
                }
                return false;
            },

            isYesOrNoQuestionTypeAnswer() {
                if (this.hasDifferentInputType) {
                    return this.questionOptions.map(function (q) {
                        return q.options.hasOwnProperty('yes_or_no_question') ? this.isYesOrNoQuestion = true : '';
                    });
                }
                return false;

            },

            questionHasAutoGenerated() {//@todo:should get survey by name id's might change
                return this.question.survey_id !== 1;
            }
        },


        methods: {

            onClickOther() {
                this.selectedAnswer = null;
                this.otherSelected = true;
            },

            handleAnswer(answerVal) {

                if (answerVal) {
                    this.otherSelected = false;
                    if (!this.isAutoGenerated()) {
                        this.selectedAnswer = answerVal;
                    }
                } else if (!this.isAutoGenerated()) {
                    this.otherSelected = true;
                    this.selectedAnswer = this.otherInputText;
                }

                if (!this.isAutoGenerated() && answerVal) {
                    this.selectedAnswer = answerVal;
                }

                const questionTypeAnswerId = this.possibleAnswers
                    .filter(possibleAnswer => {
                        /*what i want to say is - if value === null*/
                        if (possibleAnswer.value !== this.selectedAnswer) {
                            return possibleAnswer;
                        }
                        return possibleAnswer.value === this.selectedAnswer;
                    })
                    .map(questionTypeAnswer => questionTypeAnswer.id);

                const answer = {
                    value: this.selectedAnswer
                };

                this.onDoneFunc(this.question.id, questionTypeAnswerId[0], answer, this.isLastQuestion);
            },

            isAutoGenerated() {
                return this.question.conditions && this.question.conditions.is_auto_generated;
            },

            isAnswerFromOther(value) {
                return !this.possibleAnswers.some(a => a.value === value);
            },

            setSelectedForAutoGenerated() {
                let shouldDisableNextButton = false;
                const conditions = this.question.conditions.generated_from;
                const allQuestions = this.getAllQuestionsFunc();
                //auto generated results assume integer values
                let answer = 0;
                conditions.forEach(c => {
                    const q = allQuestions.find(question => question.pivot.order === c.order && question.pivot.sub_order === c.sub_order);
                    if (q && q.answer && q.answer.value) {
                        answer += parseInt(q.answer.value.value);
                    } else {
                        shouldDisableNextButton = true;
                    }
                });
                this.nextButtonDisabled = shouldDisableNextButton;
                this.selectedAnswer = answer.toString();
            },

            setSelectedFromValue(value) {
                if (typeof value === "string") {
                    this.selectedAnswer = value;
                } else if (Array.isArray(value)) {
                    this.selectedAnswer = value[0];
                } else {
                    //assume object
                    this.selectedAnswer = Object.values(value)[0];
                }
            }

        },

        created() {
            const questionOptions = this.question.type.question_type_answers.filter(question => question.options);
            this.questionOptions.push(...questionOptions);

            const possibleAnswers = this.question.type.question_type_answers.filter(possibleAnswer => possibleAnswer.value);
            this.possibleAnswers.push(...possibleAnswers);

            /*fetch input type & placeholder for the second input type*/
            if (this.hasDifferentInputType) {
                const inputTypeData = this.questionOptions.map(a => a.options);
                this.differentInputTypesData.push(...inputTypeData);
            }

            if (this.question.answer) {
                if (this.question.answer.value) {
                    this.setSelectedFromValue(this.question.answer.value);
                } else if (this.question.answer.suggested_value) {
                    this.setSelectedFromValue(this.question.answer.suggested_value);
                }
            }

            if (this.selectedAnswer && this.hasDifferentInputType && this.isAnswerFromOther(this.selectedAnswer)) {
                this.otherSelected = true;
                this.otherInputText = this.selectedAnswer;
            }

            if (this.isAutoGenerated()) {
                this.setSelectedForAutoGenerated();
            }
        },


        watch: {
            isActive(newVal, oldVal) {
                if (newVal && this.isAutoGenerated()) {
                    this.setSelectedForAutoGenerated();
                }
            }
        }
    }
</script>

<style scoped lang="scss">

    $primary-color-active: #4aa5d2;

    input.text-field {
        padding-left: 5px;
    }

    .btn.radio, input.text-field {
        text-align: left;
        margin: 0.375rem;
        float: left;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        width: 45%;
        -ms-text-overflow: ellipsis;
        text-overflow: ellipsis;
        white-space: nowrap;
        height: 50px;
        border-radius: 5px;
        border: solid 1px transparent !important;
        background-color: #ffffff !important;
        font-family: Poppins, serif;
        font-size: 18px;
        font-weight: 500;
        font-style: normal;
        text-transform: none;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 1px;
        color: #1a1a1a;
    }

    .btn.btn-primary.radio.active,
    input.text-field.active,
    input.text-field.active::placeholder {
        background-color: $primary-color-active !important;
        color: #ffffff;
    }

    input.text-field:hover,
    .btn.btn-primary.radio:hover {
        border: 1px solid $primary-color-active !important;
    }

    .btn.radio.small, input.text-field.small {
        width: 25%;
    }

    .btn.btn-sm {
        font-size: 14px;
        width: 30px;
        height: 30px;
        border: solid 1px #4aa5d2;
        margin: 1px;
        padding: 0;
        box-shadow: none;
    }

    @media (min-width: 576px) {
        .btn.btn-sm {
            font-size: 24px;
            width: 60px;
            height: 60px;
        }
    }

    .btn-primary {
        background-color: #50b2e2;
        border-color: #4aa5d2;
    }

    .text-field[disabled="disabled"],
    .btn-primary.disabled {
        opacity: 50%;
        background-color: #50b2e2;
        border-color: #4aa5d2;
    }

    .btn.btn-sm.active {
        background-color: #50b2e2 !important;
        color: white;
    }

    .custom-checkbox {
        position: relative !important;
        pointer-events: initial !important;
        opacity: 1 !important;
    }

    .custom-checkbox.disabled {
        opacity: 50%;
        pointer-events: none;
        background-color: #50b2e2;
        border-color: #4aa5d2;
    }


    @media (max-width: 490px) {
        /*Radio buttons*/
        .btn.radio {
            height: 34px;
            width: 325px;
            text-align: left;
            padding: 3px;
            padding-left: 1em;
            font-size: 13px;
        }

        /*Other buttons eg question order 11a in HRA*/
        .text-field.small, input.text-field.small {
            height: 35px;
            width: 152px;
            text-align: left;
            padding: 4px;
            font-size: xx-small;
        }

        /*Yes or No*/
        .btn.radio.small {
            height: 35px;
            width: 152px;
            text-align: left;
            font-size: 13px;
            padding-left: 1em;
        }

        /*Other button Q11c.*/
        #app div > div.question-answer-type > div > div > input {
            height: 35px;
            text-align: left;
            padding: 1em;
            font-size: xx-small;
        }
        /* Vitals Radios*/
        .btn.btn-sm {
            width: 35px;
            height: 35px;
        }
    }

    @media (max-width: 996px) {
        .btn.radio {
            height: 3em;
            text-align: left;
            padding: 3px;
            padding-left: 1em;
            font-size: 13px;
        }
    }

    @media (max-width: 996px) {
        .text-field.small, input.text-field.small {
            height: 100%;
            width: 152px;
            text-align: left;
            padding: 4px;
            font-size: xx-small;
        }

        #app div > div.question-answer-type > div > div > input {
            height: 100%;
            text-align: left;
            padding: 1em;
            font-size: xx-small;
        }
    }

</style>
