<template>
    <div>
        <!--question without sub_parts-->
        <div v-if="!questionHasSubParts">
            <input
                type="number"
                class="number-field"
                name="numberTypeAnswer[]"
                v-model="inputNumbers[0]"
                :disabled="readOnly || !isActive || isAutoGenerated"
                :placeholder="this.questionPlaceHolder">
        </div>
        <br>
        <!--question with sub_parts-->
        <div v-if="questionHasSubParts" class="row no-margin-side">
            <div v-for="(subPart, index) in questionSubParts" :key="index">
                <input type="number"
                       class="number-field"
                       :class="subPartsStyle"
                       name="numberTypeAnswer[]"
                       v-model="inputNumbers[index]"
                       :disabled="readOnly || !isActive"
                       :placeholder="subPart.placeholder">
                <span
                    v-if="questionSubPartsSeparator === 'dash' && index !== questionSubParts.length - 1">
                    &nbsp;/&nbsp;
                </span>

                <span
                    v-if="questionSubPartsSeparator === '' && index !== questionSubParts.length - 1">
                    &nbsp;
                </span>
            </div>

        </div>

        <!--next button-->
        <br>
        <mdbBtn v-show="!readOnly && isActive"
                color="primary"
                class="next-btn"
                name="number"
                id="number"
                :disabled="!hasAnyInput"
                @click="handleAnswer()">
            {{isLastQuestion ? 'Complete' : 'Next'}}
            <mdb-icon v-show="waiting" icon="spinner" :spin="true"/>
        </mdbBtn>
    </div>
</template>

<script>

    import {mdbBtn, mdbIcon} from "mdbvue";

    const AUTO_GENERATE_FUNCS = {
        'bmi_func': '703 * ($mass$ / Math.pow($height$,2))'
    };

    export default {
        name: "questionTypeNumber",
        props: ['question', 'isActive', 'isSubQuestion', 'onDoneFunc', 'isLastQuestion', 'waiting', 'readOnly', 'getAllQuestionsFunc'],
        components: {mdbBtn, mdbIcon},

        data() {
            return {
                isAutoGenerated: false,
                inputNumbers: [],
                questionOptions: [],
                showNextButton: false,
                keys: [],
            }
        },
        computed: {
            subPartsStyle() {
                return 'parts-' + this.questionSubParts.length;
            },

            hasAnyInput() {
                return this.inputNumbers[0].length > 0 ? this.showNextButton = true : this.showNextButton = false;
            },

            hasAnswerType() {
                return this.question.type.question_type_answers.length !== 0;
            },

            questionTypeAnswerId() {
                if (this.hasAnswerType) {
                    return this.question.type.question_type_answers[0].id;
                } else {
                    return undefined;
                }
            },

            questionHasSubParts() {
                if (this.hasAnswerType) {
                    return this.questionOptions[0].hasOwnProperty('sub_parts') || this.questionOptions[0].hasOwnProperty('sub-parts');
                }
                return false;
            },

            questionSubParts() {
                if (this.questionHasSubParts) {
                    return this.questionOptions[0].sub_parts || this.questionOptions[0]["sub-parts"];
                }
                return [];
            },

            questionSubPartsSeparator() {
                return this.questionOptions[0].separate_sub_parts_with || '';
            },

            questionHasPlaceHolder() {
                if (this.hasAnswerType) {
                    return this.questionOptions[0].hasOwnProperty('placeholder');
                }
                return false;
            },

            questionPlaceHolder() {
                if (this.questionHasPlaceHolder) {
                    return this.questionOptions[0].placeholder;
                }
                return '';
            },

        },

        methods: {
            hasSpecialInputFormat() {
                if (this.hasAnswerType) {
                    return this.questionOptions[0].hasOwnProperty('input_format');
                }
            },

            checkRequiredFormat(inputVal) {
                const requiredFormat = inputVal.input_format;
                if (requiredFormat === 'phone') {
                    // return inputVal.length === 10;
                    return true;
                }
            },

            handleAnswer() {
                if (!this.hasAnyInput) {
                    return;
                }

                const inputVal = this.inputNumbers;
                if (!this.questionHasSubParts && this.hasSpecialInputFormat(inputVal)) {
                    const x = this.checkRequiredFormat(inputVal);
                    console.log(x);
                }

                const keys = this.keys;
                let answer;
                if (keys.length !== 0) {
                    answer = inputVal.reduce(function (result, field, index) {
                        result[keys[index]] = field;
                        return result;
                    }, {});

                } else {
                    answer = {
                        value: inputVal
                    };
                }

                this.onDoneFunc(this.question.id, this.questionTypeAnswerId, answer, this.isLastQuestion);
            },

            getValueFromStringFunc(func, placeholders) {
                try {
                    if (!placeholders || placeholders.length === 0) {
                        return eval(func);
                    }
                    let newFunc = func;
                    placeholders.forEach(x => {
                        newFunc = newFunc.replace(`$${x.key}$`, x.value);
                    });

                    //not all placeholders were replaced
                    if (newFunc.indexOf('$') > -1) {
                        return "";
                    }

                    return eval(newFunc);
                } catch (e) {
                    console.error(e);
                    return "";
                }
            },

            getStringValueFromAnswer(answer, joinChar) {

                //sometimes we have {value: { value: [] }}
                if (answer.value && answer.value.value) {
                    answer.value = answer.value.value;
                }

                if (typeof answer.value === "string") {
                    return answer.value;
                }

                let arr = answer.value;
                if (Array.isArray(arr)) {
                    return arr.join(joinChar);
                } else {
                    //todo: read sub-parts and build the string
                    //only taking into account object of feet and inches
                    const feet = arr['feet'];
                    const inches = arr['inches'];
                    return String((+feet * 12) + (+inches));
                }
            },

            setValueFromAutoGenerated() {
                const conditions = this.question.conditions;
                if (conditions.generate_func) {
                    const func = AUTO_GENERATE_FUNCS[conditions.generate_func];
                    if (!func) {
                        console.error(`could not find ${conditions.generate_func} on auto generation functions`);
                    } else {
                        const placeholders = [];
                        const generateFrom = conditions.generated_from;
                        if (generateFrom) {
                            const questions = this.getAllQuestionsFunc();
                            generateFrom.forEach(p => {
                                //p: { order, type }
                                const targetQuestion = questions.find(q => q.pivot.order === p.order);

                                if (!targetQuestion || !targetQuestion.answer || !targetQuestion.answer.value) {
                                    return;
                                }

                                placeholders.push({
                                    key: p.type,
                                    value: this.getStringValueFromAnswer(targetQuestion.answer, '.')
                                });
                            })
                        }

                        if (placeholders.length) {
                            this.inputNumbers = [];
                            const val = Number(this.getValueFromStringFunc(func, placeholders)).toFixed(2);
                            this.inputNumbers.push(val);
                        }

                    }

                } else {
                    //todo
                }
            },

            setValueFromServer(value) {
                if (typeof value === "string") {
                    this.inputNumbers.push(value)
                } else if (Array.isArray(value)) {
                    this.inputNumbers = value;
                } else {
                    //assume object
                    this.inputNumbers = Object.values(value);
                }
            }
        },
        created() {

            const questionOptions = this.question.type.question_type_answers.map(q => q.options);
            this.questionOptions.push(...questionOptions);

            if (this.question.answer) {
                if (this.question.answer.value) {
                    this.setValueFromServer(this.question.answer.value);
                } else if (this.question.answer.suggested_value) {
                    this.setValueFromServer(this.question.answer.suggested_value);
                }
            }

            if (this.questionSubParts.length > 1) {
                const keys = (this.questionOptions[0].sub_parts || this.questionOptions[0]["sub-parts"]).map(q => q.key);
                this.keys.push(...keys);
                if (this.inputNumbers.length === 0) {
                    //just add empty inputs
                    this.inputNumbers.push(...keys.map(k => ''));
                }
            }

            if (this.inputNumbers.length === 0) {
                this.inputNumbers.push("");
            }

            this.isAutoGenerated = this.question.conditions && this.question.conditions.is_auto_generated;
        },

        mounted() {
            if (this.isAutoGenerated) {
                this.setValueFromAutoGenerated();
            }
        },

        watch: {
            isActive(newVal, oldVal) {
                if (newVal && this.isAutoGenerated) {
                    this.setValueFromAutoGenerated();
                }
            }
        }

    }
</script>

<style scoped>
    .btn-primary {
        background-color: #50b2e2;
        border-color: #4aa5d2;
    }

    .btn-primary.disabled {
        opacity: 50%;
        background-color: #50b2e2;
        border-color: #4aa5d2;
    }

    .number-field {
        border: none;
        border-bottom: solid 1px rgba(0, 0, 0, 0.1);
        background-color: transparent;
        outline: 0;
        width: 300px;
        height: 30px;
    }

    .number-field.parts-2 {
        width: 120px;
    }

    @media (max-width: 490px) {
        .number-field {
            padding-left: 9px;
            width: 325px;
        }

        .number-field.parts-2 {
            width: 157px;
        }

        .vitals .number-field {
            width: 130px;
        }

        .vitals .number-field.parts-2 {
            width: 151px;
        }
    }

</style>
